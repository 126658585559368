import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import HomeBannerImage from '../assets/images/home/landing-row-bg.png';
import { Link } from 'gatsby';
import '../assets/css/styles.css';
import { launchVideos } from '../data';
import { lng, setLang } from '../components/essentials';
import playButton from '../assets/images/icons/play-2.svg'

export default function LivePage(props) {
    setLang(props.lang ? props.lang : 'en');
    return <Layout lang={lng('en', 'ar')}>
        <SEO title="Live" />
        <main style={{ paddingTop: '100px' }}>
            <div className="uk-cover-container live-page relative" data-uk-height-viewport="min-height: 750">
                <img src={HomeBannerImage} style={{transform: "translate(-50%,-50%)"}} alt="" data-uk-cover />
                <div className="uk-overlay uk-light uk-position-center">
                    <div className="large-container uk-text-center ph5-ns pb5">
                        <div  style={{padding: "20px"}}>
                        <h2 className="mb0">
                            {lng('Watch live coverage of Hope Probe reaching Mars', 'تابعوا البث المباشر لوصول مسبار الأمل إلى المريخ')}
                        </h2>
                        </div>
                    </div>
                    <div className="large-container padding-bottom-0 ph5-ns">
                        <div className="w-100 flex items-start justify-between overflow-x-auto link-container">
                            {launchVideos.map((video, index) => (
                                <a className="w-50 w-20-ns min-width-250" data-uk-toggle={'target: #live-arabic-' + index}>
                                    <img src={video.Thumbnail && video.Thumbnail.publicURL} alt=""/>
                                    <br/>
                                    <p className="uk-text-large uk-text-center uk-text-bold text-light">
                                        {lng(video.Title, video.arabic_title)}
                                    </p>
                                </a>
                            ))}
                        </div>
                    </div>
                </div>
                {/* <div className="absolute ml4 mb4 bottom-0 left-0">
                    <Link to={lng('', '/ar') + '/live'}>
                        <button className="uk-button uk-button-default" style={{ background: 'rgba(0,0,0,0.5)', color: '#fff' }}>
                            <span uk-icon="icon: triangle-right; ratio: 2" style={{ marginLeft: '-20px' }}></span>
                            <span className="f5 b">{lng('Watch Launch Videos', 'تابعوا فيديوهات الإطلاق')}</span>
                        </button>
                    </Link>
                </div> */}
            </div>

            {launchVideos.map((video, index) => (
                <div id={'live-arabic-' + index} className="uk-flex-top" data-uk-modal key={index}>
                    <div className="uk-modal-dialog uk-width-auto uk-margin-auto-vertical">
                        <button className="uk-modal-close-outside" type="button" data-uk-close></button>
                        <iframe src={video.URL} allowFullScreen="1" className="live-video-iframe" frameborder="0" data-uk-video data-uk-responsive></iframe>
                    </div>
                </div>
            ))}
        </main>
    </Layout>
}